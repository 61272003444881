* {
    box-sizing: border-box;
  }

  .App {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding-top: 80px;
  }

  .App > h1 {
    margin-bottom: 5px;
  }

  .App > h2 {
    margin-top: 5px;
    opacity: 0.7;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }

.system {
    background: #fff;
    position: relative;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    width: 25%;
    color: #333;
    flex-basis: width;
    background-color: rgb(165, 230, 230);
  }

  .systems {
    display: flex;
    flex-wrap: wrap;
  }


.system h3 {
    margin-bottom: 5px;
    font-size: 1rem;
    opacity: 0.7;
  }

  .system h2 {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 1.5rem;
  }

  .details {
    text-align: left;
  }