/* Round circle in orange color */
.circle-container {
  position: relative;
  /* 1 */
  width: 40em;
  height: 40em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  /* 2 */
  box-sizing: content-box;
  /* 3 */
  margin: 5em auto 0;
  border: solid 2px black;
}
.circle-container > * {
  /* 4 */
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5em;
  height: 5em;
  margin: -3em;
}

/* Images to display in circle */
.circle-container > :nth-of-type(1) {
  transform: rotate(0deg) translate(10em) rotate(-0deg);
}
.circle-container > :nth-of-type(2) {
  transform: rotate(90deg) translate(10em) rotate(-90deg);
}
.circle-container > :nth-of-type(3) {
  transform: rotate(180deg) translate(10em) rotate(-180deg);
}
.circle-container > :nth-of-type(4) {
  transform: rotate(270deg) translate(10em) rotate(-270deg);
}
/* arrow animation */
.circle-container-animation > :nth-of-type(1) {
  transform: rotate(90deg) translate(7em) rotate(-0deg);
}
.circle-container-animation > :nth-of-type(2) {
  transform: rotate(-90deg) translate(4em) rotate(-180deg);
}
.circle-container-animation > :nth-of-type(3) {
  transform: rotate(270deg) translate(1em, -5em) rotate(-270deg);
}
.circle-container-animation > :nth-of-type(4) {
  transform: rotate(270deg) translate(2em, 5em) rotate(-270deg);
}
/* Lables to display values */
.circle-container-lbl > :nth-of-type(1) {
  transform: rotate(90deg) translate(7em, 1em) rotate(-90deg);
}
.circle-container-lbl > :nth-of-type(2) {
  transform: rotate(-90deg) translate(4.5em, -2.2em) rotate(-270deg);
}
.circle-container-lbl > :nth-of-type(3) {
  transform: rotate(270deg) translate(1.5em, -5em) rotate(-270deg);
}
.circle-container-lbl > :nth-of-type(4) {
  transform: rotate(250deg) translate(0.8em, 8em) rotate(-250deg);
}

.circle-container img {
  display: block;
  width: 100%;
  border-radius: 0%;
  /* filter: grayscale(100%); */
}
/* .circle-container img:hover {
  filter: grayscale(0);
} */

/* Energy flow animation */

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.arrow1 {
  transform: rotate(180deg) !important;
  top: 100px;
  left: 40px;
}
.arrow2 {
  transform: rotate(360deg) !important;
  top: -28px;
  left: 40px;
}
.arrow3 {
  transform: rotate(270deg) !important;
  left: -25px;
}
.arrow4 {
  transform: rotate(270deg) !important;
  right: 0;
  left: 110px;
  top: 35px;
}
/* Negative value comes arrow flow reverse direction */
.arrow4-reverse {
  transform: rotate(90deg) !important;
  right: 0;
  left: 110px;
  top: 35px;
}

.arrow3-reverse {
  transform: rotate(90deg) !important;
  left: -28px;
  top: 45px;
}
.arrow1-reverse {
  transform: rotate(360deg) !important;
  top: 100px;
  left: 42px;
}

/* .arrow span{
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 5px solid #06A8FF;
  border-right: 5px solid #06A8FF;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
} */

/* Color of arrows change according to values */
.classGreen span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #00897b;
  border-radius: 100%;
  transform: rotate(45deg);
  margin: -4px;
  animation: animate 2s infinite;
}

.classYellow span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #fbc02d;
  border-radius: 100%;
  transform: rotate(45deg);
  margin: -4px;
  animation: animate 2s infinite;
}

.classOrange span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #fb8c00;
  border-radius: 100%;
  transform: rotate(45deg);
  margin: -4px;
  animation: animate 2s infinite;
}
.classRed span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #e64a19;
  border-radius: 100%;
  transform: rotate(45deg);
  margin: -4px;
  animation: animate 2s infinite;
}
.classWhite span {
  display: block;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 100%;
  transform: rotate(45deg);
  margin: -4px;
}

/* Zero value */
.disabled-link {
  pointer-events: none;
}

/* arrow flow speed and direction */
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

/* battery css */

.batteryNotch {
  width: 10px;
  height: 30px;
}

.batteryDiv {
  width: 45px;
  height: 80px;
  position: relative;
  left: 20px;
}

.vertical {
  display: grid;
  width: 100%;
  height: 50px;
  align-items: end;
  border-radius: 4px;
  background-color: #e7eded;
  text-align: center;
  color: black;
  font-size: 12px;
}

.batteryNotch {
  width: 15px;
  height: 8px;
  background-color: #c8cac7;
  margin: auto;
}

.indicator {
  width: 100%;
  background-color: green;
  border-radius: 4px;
}

.batteryPercent {
  position: absolute;
  right: 10px;
  top: 35px;
  color : black
}

/*System icon css */

.verticalsys {
  height: 70px;
  width: 100px;
  background-color: rgb(42, 165, 128);
  border-radius: 4px;
}

.systemLable {
  position: absolute;
  right: -40px;
  top: 10px;
  color : black
}


/*Dotted circle */
.stroke-1 {
  stroke: red;
  stroke-dasharray: 0;
}

.stroke-2 {
  stroke: lightgreen;
  stroke-dasharray: 2;
}

.module {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  fill: white;
  stroke-width: 5%;
}